<template>
  <div class="ml-2 mt-2">
    <b-nav
      card-header
      class="m-0"
      pills
    >
      <b-nav-item
        :to="{ name: $route.meta.attendanceVacationsExistent }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.attendanceVacationsExistent"
      >
        Active
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.attendanceVacationsDeleted }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.attendanceVacationsDeleted"
      >
        Deleted
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>

    <ModalAddVacation
      v-if="modal"
      :operation="operation"
      :vacation="vacationSelected"
      :usersarray="usersArray"
      @hidden="modal = false"
      @closeModal="modal = false"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>

import Fields from '@/views/assistance/views/dashboard/components/vacations/views/data/fields.vacations.data';
import filtersData from '@/views/assistance/views/dashboard/components/vacations/views/data/filters.vacations.data';
import Service from '@/views/assistance/views/dashboard/components/vacations/services/vacations.service';
import ModalAddVacation from '@/views/assistance/views/dashboard/components/vacations/views/components/ModalAddVacations.vue';

export default {

  components: {
    ModalAddVacation,
  },
  data() {
    return {
      newForm: {
        employee_id: null,
        start: null,
        end: null,
        observations: '',
      },
      operation: 1,
      modal: false,
      vacationSelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'name',
      sortDesc: false,
      fields: Fields,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Employee name...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      usersArray: [],
    };
  },

  computed: {
    visibleFields() {
      return this.fields;
    },
  },
  async created() {
    await this.listAllUsers();
  },

  methods: {

    isPastDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();

      if (date > now) {
        return true;
      }
      return false;
    },

    async deleteVacations(obj) {
      const resultState = await this.showConfirmSwal();

      if (resultState) {
        const resul = await Service.deleteVacations(obj);

        if (resul.status === 200) {
          this.showSuccessSwal();
          this.refreshTable();
        }
      }
    },

    refreshTable() {
      this.$refs.refVacationsTable.refresh();
    },

    openModal(value, vacation) {
      this.operation = value;
      this.vacationSelected = vacation;
      this.modal = true;
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          employee_id: this.filter[0].model,
          from: this.filter[1].model,
          to: this.filter[2].model,
          month: this.filter[3].model,
          year: this.filter[4].model,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
        };

        const res = await Service.getVacations(obj);
        if (res.status === 200) {
          this.clients = res.data.data;
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;

          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async listAllUsers() {
      try {
        const res = await Service.listAllUsers();
        if (res.status === 200) {
          this.filter[0].options = res.data;
          this.usersArray = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.tdClass {
  font-size: 14px;
  padding: 7px 10px !important;
}
</style>
