export default [
  {
    key: 'employee',
    label: 'employee',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'start',
    label: 'start',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'end',
    label: 'end',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'hours',
    label: 'hours',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'createdby',
    label: 'created by',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'updatedby',
    label: 'updated by',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'actions',
    label: 'actions',
    visible: true,
    tdClass: 'tdClass',
  },
]
