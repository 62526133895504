<template>
  <div>
    <b-modal
      ref="modal-add-vacation"
      no-close-on-backdrop
      size="lg"
      :title="title + ' VACATION'"
      @hidden="$emit('hidden')"
    >
      <div class="m-2">
        <validation-observer ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <b-form-group label="Employee">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="employee"
                >
                  <v-select
                    id="id"
                    v-model="form.employee_id"
                    :options="usersarray"
                    :reduce="(val) => val.id"
                    label="employee"
                    placeholder="Select a employee ..."
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="form-group col-md-6">
              <b-form-group label="Start">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="start"
                >
                  <kendo-datepicker
                    id="startDate"
                    v-model="form.start"
                    v-mask="'##/##/####'"
                    :format="'MM/dd/yyyy'"
                    :class="{ 'border-danger': errors[0] || validateStart || calculateDateRange }"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <small
                    v-if="validateStart"
                    class="text-danger"
                  >
                    Invalid date , start date cannot be greater than end date
                  </small>
                  <small
                    v-if="calculateDateRange"
                    class="text-danger"
                  >
                    Range of dates canot be greater than one month (30 days)
                  </small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="form-group col-md-6">
              <b-form-group label="End">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="end"
                >
                  <kendo-datepicker
                    id="endDate"
                    v-model="form.end"
                    v-mask="'##/##/####'"
                    :format="'MM/dd/yyyy'"
                    :class="{ 'border-danger': errors[0] || calculateDateRange }"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                    :min="parsedDateEnd"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="observations"
              >
                <b-form-group label="Observations">
                  <b-form-textarea
                    v-model="form.observation"
                    class="form-control"
                    rows="3"
                    max-rows="8"
                    :class="errors[0]"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        </validation-observer>
        <b-row
          v-if="validateDates != [] && form.start != null && form.end != null"
        >
          <b-col>
            <h4>Validate Hours Date</h4>
            <hr>
            <template>

              <div
                class="scroll-container"
                style="overflow-X:hidden;overflow-Y:scroll;max-height:300px;"
              >
                <validation-observer ref="formHours">
                  <ul>
                    <li
                      v-for="(validateDate,index) in validateDates"
                      :key="validateDate.id"
                      class="list-unstyled"
                    >
                      <b-row>
                        <b-col cols="6">
                          <span>{{ validateDate.date | myDateGlobal }}</span>
                        </b-col>

                        <b-col cols="2">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="`hour-${index}`"
                            rules="required|between:0,8"
                          >
                            <b-form-input
                              v-model="validateDate.hours"
                              type="number"
                              min="0"
                              max="8"
                              :max-length="2"
                              :state="errors[0] ? false : null"
                              :class="errors[0] ? 'border-danger' : null"
                              class="mb-0"
                            />
                            <small
                              v-if="errors[0]"
                              class="text-danger"
                            >{{
                              validateDate.hours > 8 ? "Max 8" : errors[0]
                            }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </validation-observer>
              </div>
            </template>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div>
          <b-button
            class=""
            @click="cancel()"
          >
            CANCEL
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="createVacation()"
          ><feather-icon
             icon="SaveIcon"
             class="mr-1"
           />
            {{ textButton }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Service from '@/views/assistance/views/dashboard/components/vacations/services/vacations.service';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    operation: {
      type: Number,
      required: true,
    },
    vacation: {
      type: Object,
      required: true,
    },
    usersarray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      title: '',
      textButton: 'SAVE',
      validateDates: [],
      validateCounterStartDate: 0,
      validateCounterEndDate: 0,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    parsedDateEnd() {
      if (this.form.start !== null) {
        const date = new Date(this.form.start);
        return date;
      }
      return null;
    },
    calculateDateRange() {
      let validation = false;
      let numberOfDays = 0;
      if (this.form.start != null && this.form.end != null) {
        const startDate = new Date(this.form.start);
        const endDate = new Date(this.form.end);
        const timeDifference = endDate.getTime() - startDate.getTime();
        numberOfDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
        if (numberOfDays > 30) {
          validation = true;
        }
      }
      return validation;
    },

    validateStart() {
      let numberOfDays = false;
      const startDate = new Date(this.form.start);
      const endDate = new Date(this.form.end);

      if (startDate > endDate) {
        numberOfDays = true;
      }
      return numberOfDays;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.start': function (val) {
      if (val) {
        if (this.operation === 1) {
          this.validateCounterStartDate = 1;
        }
        if (this.validateCounterStartDate === 1) {
          this.listarFechas();
        }

        if (this.operation === 2) {
          this.validateCounterStartDate = 1;
        }
      }
    },
    // eslint-disable-next-line func-names
    'form.end': function (val) {
      if (val) {
        if (this.operation === 1) {
          this.validateCounterEndDate = 1;
        }

        if (this.validateCounterEndDate === 1) {
          this.listarFechas();
        }

        if (this.operation === 2) {
          this.validateCounterEndDate = 1;
        }
      }
    },
  },

  mounted() {
    this.toggleModal('modal-add-vacation');

    if (this.operation === 1) {
      this.title = 'ADD';
    }

    if (this.operation === 2) {
      this.title = 'UPDATE';
      this.form = { ...this.vacation };
      const dates = JSON.parse(this.vacation.justification_hours);
      this.validateDates = dates;
    }
  },

  methods: {
    listarFechas() {
      this.validateDates = [];
      const fechaInicial = moment(this.form.start);
      const fechaFinal = moment(this.form.end);
      const fechas = [];
      let vid = 0;

      while (fechaInicial <= fechaFinal) {
        const dayOfWeek = fechaInicial.day();
        let hours = 0;

        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          hours = 8; // Lunes a viernes
        } else if (dayOfWeek === 6) {
          hours = 6; // Sábado
        }

        fechas.push({
          id: vid,
          date: fechaInicial.format('YYYY-MM-DD'),
          hours,
        });

        fechaInicial.add(1, 'day');
        vid += 1;
      }

      this.validateDates = fechas;
    },

    cancel() {
      this.$emit('closeModal');
    },

    async createVacation() {
      try {
        const result = await this.$refs.observer.validate();
        if (!result || this.validateStart || this.calculateDateRange) {
          return;
        }
        const resultState = await this.showConfirmSwal();

        if (resultState.isConfirmed) {
          this.form.operation = this.operation;
          this.form.created_by = this.currentUser.user_id;
          this.form.validateDates = this.validateDates;
          this.form.start = moment(this.form.start).format('YYYY-MM-DD');
          this.form.end = moment(this.form.end).format('YYYY-MM-DD');
          this.addPreloader();

          const resul = await Service.addVacations(this.form);
          if (resul.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            this.$emit('closeModal');
            this.$emit('refreshTable');
          }
          if (resul.status === 207) {
            this.showWarningSwal(resul.data);
            this.removePreloader();
          }
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>
