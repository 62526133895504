/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios'

class VacationService {
  async getVacations(body) {
    const data = await amgApi.post('/attendance/get-vacations-attendance', body)
    return data
  }

  async addVacations(form) {
    const data = await amgApi.post('/attendance/add-vacations-attendance', form)
    return data
  }

  async deleteVacations(form) {
    const data = await amgApi.post('/attendance/delete-vacations-attendance', form)
    return data
  }

  async listAllUsers() {
    const data = await amgApi.post('/attendance/get-vacations-employees')
    return data
  }
}

export default new VacationService()
