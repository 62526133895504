var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-add-vacation",attrs:{"no-close-on-backdrop":"","size":"lg","title":_vm.title + ' VACATION'},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" CANCEL ")])],1),_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.createVacation()}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.textButton))],1)],1)]},proxy:true}])},[_c('div',{staticClass:"m-2"},[_c('validation-observer',{ref:"observer"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('b-form-group',{attrs:{"label":"Employee"}},[_c('validation-provider',{attrs:{"rules":"required","name":"employee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id","options":_vm.usersarray,"reduce":function (val) { return val.id; },"label":"employee","placeholder":"Select a employee ..."},model:{value:(_vm.form.employee_id),callback:function ($$v) {_vm.$set(_vm.form, "employee_id", $$v)},expression:"form.employee_id"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"form-group col-md-6"},[_c('b-form-group',{attrs:{"label":"Start"}},[_c('validation-provider',{attrs:{"rules":"required","name":"start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:{ 'border-danger': errors[0] || _vm.validateStart || _vm.calculateDateRange },attrs:{"id":"startDate","format":'MM/dd/yyyy'},model:{value:(_vm.form.start),callback:function ($$v) {_vm.$set(_vm.form, "start", $$v)},expression:"form.start"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.validateStart)?_c('small',{staticClass:"text-danger"},[_vm._v(" Invalid date , start date cannot be greater than end date ")]):_vm._e(),(_vm.calculateDateRange)?_c('small',{staticClass:"text-danger"},[_vm._v(" Range of dates canot be greater than one month (30 days) ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"form-group col-md-6"},[_c('b-form-group',{attrs:{"label":"End"}},[_c('validation-provider',{attrs:{"rules":"required","name":"end"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:{ 'border-danger': errors[0] || _vm.calculateDateRange },attrs:{"id":"endDate","format":'MM/dd/yyyy',"min":_vm.parsedDateEnd},model:{value:(_vm.form.end),callback:function ($$v) {_vm.$set(_vm.form, "end", $$v)},expression:"form.end"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"form-group col-md-12"},[_c('validation-provider',{attrs:{"rules":"required","name":"observations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Observations"}},[_c('b-form-textarea',{staticClass:"form-control",class:errors[0],attrs:{"rows":"3","max-rows":"8","state":errors.length > 0 ? false : null},model:{value:(_vm.form.observation),callback:function ($$v) {_vm.$set(_vm.form, "observation", $$v)},expression:"form.observation"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)])]),(_vm.validateDates != [] && _vm.form.start != null && _vm.form.end != null)?_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Validate Hours Date")]),_c('hr'),[_c('div',{staticClass:"scroll-container",staticStyle:{"overflow-X":"hidden","overflow-Y":"scroll","max-height":"300px"}},[_c('validation-observer',{ref:"formHours"},[_c('ul',_vm._l((_vm.validateDates),function(validateDate,index){return _c('li',{key:validateDate.id,staticClass:"list-unstyled"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(_vm._f("myDateGlobal")(validateDate.date)))])]),_c('b-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":("hour-" + index),"rules":"required|between:0,8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",class:errors[0] ? 'border-danger' : null,attrs:{"type":"number","min":"0","max":"8","max-length":2,"state":errors[0] ? false : null},model:{value:(validateDate.hours),callback:function ($$v) {_vm.$set(validateDate, "hours", $$v)},expression:"validateDate.hours"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validateDate.hours > 8 ? "Max 8" : errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1)}),0)])],1)]],2)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }