export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Employee',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'employee',
    cols: 12,
  },

  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Start',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },

  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'End',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Month',
    model: null,
    options: [
      { value: null, label: "All" },
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Year',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  },
]
